"use client";
import React from "react";
import { Next13ProgressBar } from "next13-progressbar";

const ProgressbarWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      {children}
      <Next13ProgressBar
        height="4px"
        color="white"
        options={{ showSpinner: true }}
        showOnShallow={false}
      />
    </>
  );
};

export default ProgressbarWrapper;
