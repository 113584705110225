import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Space_Grotesk\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"],\"variable\":\"--font-space-grotesk\"}],\"variableName\":\"spaceGrotesk\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Space_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"weight\":[\"400\",\"700\"],\"variable\":\"--font-space-mono\"}],\"variableName\":\"spaceMono\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/sourceful-frontend/app/components/draftMode/DraftModeCloseButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorProvider"] */ "/app/sourceful-frontend/app/providers/ErrorProvider/ErrorProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/sourceful-frontend/app/providers/LDProvider/LDProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/sourceful-frontend/app/providers/wrappers/ProgressbarWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/app/sourceful-frontend/app/styles.css");
