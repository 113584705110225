import { ComponentType, useCallback, useEffect } from "react";
import { withLDProvider, useLDClient, useFlags } from "launchdarkly-react-client-sdk";
import { LDSingleKindContext, LDFlagSet } from "launchdarkly-js-sdk-common";
import { User } from "@auth0/auth0-react";

import { FlagName } from "./flag-names";
import { ANONYMOUS_USER_KEY } from "./constants";
import { isMobileUserAgent } from "../mobile-detection";
import { LocalFlagLoader } from "./local-overrides";

/* This file provides an abstraction layer between our feature flag interface and the underlying LaunchDarkly sdk */

// Runs on the server so be checking anything client side related
interface FlagUtilProps {
  app: ComponentType<any>;
  launchDarklyId: string;
  guestUserId: string;
  isTrackingDisabled: boolean;
}

export const withFlagProvider = ({
  app,
  launchDarklyId,
  guestUserId,
  isTrackingDisabled,
}: FlagUtilProps) => {
  if (!launchDarklyId) {
    console.error("Missing Launch Darkly id");
  }
  console.debug("Launch Darkly Client ID", launchDarklyId);

  return withLDProvider({
    clientSideID: launchDarklyId,
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
    options: {
      disableSyncEventPost: true, // see https://github.com/launchdarkly/js-client-sdk/issues/147
    },
    context: {
      kind: "user",
      key: guestUserId || ANONYMOUS_USER_KEY, // default to guest user id
      anonymous: false,
      uniqueId: guestUserId,
      trackingDisabled: isTrackingDisabled,
    },
  })(app);
};

const getNavigatorProps = ():
  | {}
  | {
      connectionDownlink: number;
      // @ts-ignore
      connectionType: ConnectionType;
      connectionEffectiveType: string;
    } => {
  // @ts-ignore
  if (typeof navigator.connection === "undefined") {
    return {};
  }

  return {
    // @ts-ignore
    connectionDownlink: navigator.connection.downlink as number,
    // @ts-ignore
    connectionType: navigator.connection.type,
    // @ts-ignore
    connectionEffectiveType: navigator.connection.effectiveType as string,
  };
};

export interface UseIdentifyFeatureFlagUserProps {
  user: User | null;
  setIsIdentified?: () => void;
  guestUserId: string;
  isTrackingDisabled: boolean;
}

export const useIdentifyFeatureFlagUser = ({
  user,
  guestUserId,
  isTrackingDisabled,
}: UseIdentifyFeatureFlagUserProps) => {
  let ldClient = useLDClient();

  function launchDarklyErrorHandler(e: Error) {
    if ((e as Error).name === "LaunchDarklyFlagFetchError") return;
    console.error(e);
  }

  useEffect(() => {
    ldClient?.on("error", launchDarklyErrorHandler);

    if (!user?.sub) {
      const formattedGuestUser: LDSingleKindContext = {
        kind: "user",
        key: guestUserId,
        anonymous: false,
        uniqueId: guestUserId, // use the guest id as a stable reference to link guest / logged in user experiences for percentage rollouts see https://docs.launchdarkly.com/home/flags/rollouts#maintaining-user-experience-across-anonymous-and-logged-in-states
        trackingDisabled: isTrackingDisabled,
        userAgent: navigator.userAgent,
        isMobile: isMobileUserAgent(),
        ...getNavigatorProps(),
      };

      ldClient?.identify(formattedGuestUser, undefined, () => {
        console.log("LaunchDarkly guest identified", formattedGuestUser);
      });

      return;
    }

    // NOTE we always use the guest user id as the stable key because LD experiments don't support
    // percentage rollouts based on custom fields, which is available on a normal percentage
    // rollout without experimentation.
    const formattedUser: LDSingleKindContext = {
      kind: "user",
      key: guestUserId,
      email: user?.email,
      name: user?.given_name,
      lastName: user?.family_name,
      anonymous: false,
      auth0Id: user?.sub,
      // note: we currently don't need this because Launch Darkly experiments can't work with using custom fields as the variation key but this will be useful in the future
      uniqueId: guestUserId, // use the guest id as a stable reference to link guest / logged in user experiences for percentage rollouts see https://docs.launchdarkly.com/home/flags/rollouts#maintaining-user-experience-across-anonymous-and-logged-in-states
      trackingDisabled: isTrackingDisabled,
      userAgent: navigator.userAgent,
      isMobile: isMobileUserAgent(),
      ...getNavigatorProps(),
    };

    ldClient?.identify(formattedUser, undefined, () => {
      console.log("LaunchDarkly user identified", formattedUser);
    });

    return () => {
      ldClient?.off("error", launchDarklyErrorHandler);
    };
  }, [user?.sub, user?.email, user?.given_name, user?.family_name, ldClient, guestUserId]);
};

export interface CreateUseFeatureFlagOptions {
  useLocalOverride: (flag: FlagName) => any;
  isFeatureFlagProviderDisabled: () => boolean;
}

export const createUseFeatureFlag = ({
  useLocalOverride,
  isFeatureFlagProviderDisabled,
}: CreateUseFeatureFlagOptions) => {
  return (
    flag: FlagName,
    fallbackValue?: any,
    useFetchFlags: () => LDFlagSet = isFeatureFlagProviderDisabled() ? () => ({}) : useFlags
  ) => {
    const flags = useFetchFlags();

    // load in any local overrides defined in flag-overrides.ts - useful for local dev
    const localOverride = useLocalOverride(flag);

    if (localOverride !== undefined && localOverride !== null) {
      return localOverride;
    }

    if (flags[flag] === undefined && fallbackValue !== undefined) {
      return fallbackValue;
    }

    return flags[flag];
  };
};

export type ExperimentEvent =
  | "customise-clicked"
  | "create-account-artwork"
  | "create-account-exit"
  | "submit-pclive-quote"
  | "enter-studio"
  | "artwork-help"
  | "add-to-basket"
  | "download-dieline";

export const createUsePushExperimentEvent = (loadLocalOverrides: LocalFlagLoader) => {
  return ({
    isFeatureFlagProviderDisabled,
    isTrackingDisabled,
  }: {
    isFeatureFlagProviderDisabled: boolean;
    isTrackingDisabled: boolean;
  }) => {
    let ldClient = useLDClient();
    return useCallback(
      async (event: ExperimentEvent, data?: any) => {
        if (isFeatureFlagProviderDisabled || isTrackingDisabled) {
          return;
        }

        const localOverrides = loadLocalOverrides();

        if (localOverrides && Object.keys(localOverrides).length > 0) {
          return console.warn(
            `BLOCKED push of experiment metric as local overrides are present - using local overrides with experiment metrics leads to misattributions`
          );
        }

        try {
          await ldClient?.waitForInitialization();
          ldClient?.track(event, data);
        } catch (e) {
          console.error(e);
        }
      },
      [ldClient]
    );
  };
};
