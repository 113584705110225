import Cookies from "js-cookie";
import { useMemo } from "react";
import { LDFlagValue } from "launchdarkly-js-sdk-common";

import { FEATURE_FLAGS_COOKIE_KEY } from "./constants";
import { FlagName } from "./flag-names";

export type LocalFlagLoader = () => { [key in FlagName]?: LDFlagValue };

export const createLoadLocalOverrides = (defaultLoader: LocalFlagLoader) => () => {
  const overrides = defaultLoader();

  let cookieFlags;
  const cookie = Cookies.get(FEATURE_FLAGS_COOKIE_KEY);
  if (cookie) {
    cookieFlags = JSON.parse(cookie);
  }

  return {
    ...cookieFlags,
    ...overrides,
  };
};

export const createUseLocalOverride = (loadLocalFlagOverrides: LocalFlagLoader) => {
  return (flag: FlagName) => {
    // load in any local overides defined in flag-overrides.ts - useful for local dev
    const localOverrides = loadLocalFlagOverrides();
    const rawValue = localOverrides ? localOverrides[flag] : undefined;

    const flagValue = useMemo(() => {
      if (rawValue !== undefined) {
        console.debug(`Local override found for flag: ${flag}`, rawValue);
        return rawValue;
      }

      return null;
    }, [flag, rawValue]);

    return flagValue;
  };
};
