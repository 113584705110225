"use client";

import { withFlagProvider } from "@sourceful/shared-utils/flag-utils";
import { createGuestUserIdFetcher } from "@sourceful/shared-utils/guest-utils";
import Cookies from "js-cookie";
import { isFeatureFlagProviderDisabled } from "../../../lib/flags/flags.client";
import { COOKIE_DISABLE_TRACKING_KEY } from "../../../lib/hooks/useTracking.constants";
import env from "../../../lib/utils/env";

const ROOT_DOMAIN = env("ROOT_HOSTNAME");
const LAUNCHDARKLY_CLIENTSIDE_SDK_KEY = env("LAUNCHDARKLY_CLIENTSIDE_SDK_KEY");

export const getGuestUserId = createGuestUserIdFetcher(ROOT_DOMAIN);
export const isTrackingDisabled = () => !!Cookies.get(COOKIE_DISABLE_TRACKING_KEY);

function LDProvider({ children }: Readonly<{ children: React.ReactNode }>) {
  return <>{children}</>;
}

const AppWithFlags = isFeatureFlagProviderDisabled()
  ? LDProvider
  : withFlagProvider({
      app: LDProvider,
      launchDarklyId: LAUNCHDARKLY_CLIENTSIDE_SDK_KEY,
      guestUserId: getGuestUserId(),
      isTrackingDisabled: isTrackingDisabled(),
    });

export default AppWithFlags;
