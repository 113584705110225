"use client";

import { CloseButton } from "@sourceful/design-system-v3";
import { usePathname } from "next/navigation";

export default function DraftModeCloseButton() {
  const pathname = usePathname();

  return (
    <CloseButton
      type="button"
      size="sm"
      text="Exit draft mode"
      className="absolute top-4 right-4 text-ui-lightest bg-ui-green-600 rounded-none hover:bg-ui-green-600 focus:bg-ui-green-600 pointer-events-auto"
      onClick={e => {
        e.preventDefault();
        window.location.href = `/api/exit-draft?returnTo=${pathname}`;
        // Weird behaviour using router.push, it would hit the API but then the response would be the redirect fetch response...
        //router.push(`/api/exit-draft?returnTo=${pathname}`);
      }}
    />
  );
}
